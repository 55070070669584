import { combineReducers } from '@reduxjs/toolkit';

// Reducers
import { QuestsReducer } from './reducers/quests';
import { ProfileReducer } from './reducers/profile';
import { ReferralsReducer } from './reducers/referrals';
import { AviatorReducer } from './reducers/aviator';
import { SettingsReducer } from './reducers/settings';
import { LeaderboardReducer } from './reducers/leaderboard';
import { AuthReducer } from './reducers/auth';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  quests: QuestsReducer,
  profile: ProfileReducer,
  referrals: ReferralsReducer,
  aviator: AviatorReducer,
  settings: SettingsReducer,
  leaderboard: LeaderboardReducer,
});
