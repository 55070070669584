import { ReactComponent as CircleCheck } from '../../assets/svg/circleCheck.svg';
import { ReactComponent as Coin } from '../../assets/svg/coin.svg';
import { QuestT } from '../../types';
import { numberWithCommas } from '../../helpers';

type DailyQuestProps = {
    quest: QuestT
}
function DailyQuest({ quest }: DailyQuestProps) {
  const isCompleted = !!quest.progress?.completed_at;
  const isFuture = !quest.progress;
  return (
    <div className={`
    ${isCompleted && 'border-yellow-1 border'} 
    ${(!isCompleted && !isFuture) && 'bg-dailyCoinBgStar bg-dailyCoinBgStarPosition bg-no-repeat'} 
    rounded-10 flex-[18%] flex flex-col items-center justify-between bg-opacity-15 bg-white relative overflow-hidden px-1`}
    >
      {isFuture
       && <div className="bg-white bg-opacity-10 absolute h-full w-full" />}
      <span className="mt-1">
        {quest.name}
      </span>
      {isCompleted
        ? <CircleCheck stroke="#FCE33A" />
        : (
          <Coin stroke="none" />
        )}
      <span className="mb-1">{numberWithCommas(quest.rewards)}</span>
    </div>
  );
}

export default DailyQuest;
