import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAppSelector } from '../../store';
import { AviatorGameWSMessages } from './types';

type StopGameT = {
  aviatorId: number
  hash: string
}
interface WebSocketContextProps {
    webSocket: WebSocket | null;
    startGame: (multiplier: number | undefined) => void;
    stopGame: (data: StopGameT) => void;
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(undefined);

export const useWebSocketContext = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error(
      'useWebSocketContext must be used within an WebSocketProvider',
    );
  }

  return context;
};

function WebSocketProvider({ children }: { children: React.ReactNode }) {
  const [webSocket, setWebSocket] = useState<WebSocketContextProps['webSocket']>(null);
  const {
    tgProfileData, userData,
  } = useAppSelector(({ profile }) => profile);
  const [pingInterval, setPingInterval] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    const protocol = process.env.REACT_APP_ENV === 'local' ? 'ws' : 'wss';
    const ws = new WebSocket(`${protocol}://${process.env.REACT_APP_WS_PATH}?token=${token}`);
    ws.onopen = () => {
      window.console.log('Connected to WebSocket server');

      const intervalId = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ type: 'ping' }));
        }
      }, 50000);

      setPingInterval(intervalId);
    };

    ws.onmessage = (event: MessageEvent<string>) => {
      const message: AviatorGameWSMessages = JSON.parse(event.data);

      if (message.type === 'start') {
        window.console.log('Game started:', message);
      } else if (message.type === 'progress') {
        window.console.log('Game progress:', message.data.currentValue);
      } else if (message.type === 'result') {
        window.console.log('Game result:', message);
      } else if (message.type === 'error') {
        window.console.log('Game error:', message);
      }
    };

    ws.onclose = (event) => {
      window.console.log(`WebSocket closed: Code=${event.code}, Reason=${event.reason}`);
      if (pingInterval) clearInterval(pingInterval);
    };

    ws.onerror = (error) => {
      window.console.error('WebSocket error:', JSON.stringify(error));
    };

    setWebSocket(ws);

    return () => {
      if (pingInterval) clearInterval(pingInterval);
    };
  }, []);

  const startGame = (multiplier: number) => {
    webSocket?.send(
      JSON.stringify({ type: 'start', value: multiplier }),
    );
  };

  const stopGame = ({ aviatorId, hash }: StopGameT) => {
    webSocket?.send(
      JSON.stringify({ type: 'stop', aviatorId, hash }),
    );
  };

  const contextValue = useMemo(() => ({
    webSocket, startGame, stopGame,
  }), [webSocket, startGame]);

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketProvider;
