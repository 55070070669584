// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import { axiosInstance } from '../../index';
import { AviatorStartResponse } from '../../../types';
// Types

export const startAviator = createAsyncThunk<AviatorStartResponse, {multipler: number}>(
  'aviator/start',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('/aviator/start');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
