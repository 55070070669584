import { useEffect, useState } from 'react';

export const useCountDown = (initialSeconds: number) => {
  const [counter, setCounter] = useState<number>(initialSeconds);
  useEffect(() => {
    if (counter === 0) {
      return;
    }
    setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);
  }, [counter]);
  const hours = Math.floor(counter / 3600);
  const minutes = Math.floor((counter % 3600) / 60);
  const seconds = counter % 60;
  const formatedSecond = seconds < 10 ? `0${seconds}` : seconds.toString();
  const formatedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();
  const restart = (val: number) => {
    setCounter(val);
  };
  return {
    hours, minutes, seconds, formatedSecond, formatedMinutes, restart,
  };
};
