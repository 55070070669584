// Core
import { createAsyncThunk } from '@reduxjs/toolkit';

// Store
import { AxiosError } from 'axios';
import {
  CreateUserT,
  ProfileResponseT,
  UpdateProfileCoins,
  UpdateTGProfileData,
  UpdateProfileEnergy,
  UpdateUserData,
  UpdateProfileEnergyResponse,
} from '../../../types';
import { axiosInstance, RootState } from '../../index';
// Types

export const updateProfileCoins = createAsyncThunk<
    UpdateProfileCoins,
    UpdateProfileCoins
>(
  'profile/coins/update',
  async (quest, { rejectWithValue }) => {
    try {
      return quest;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const updateProfileEnergy = createAsyncThunk<
    UpdateProfileEnergyResponse,
    UpdateProfileEnergy
>(
  'profile/energy/update',
  async (energy, { rejectWithValue, getState }) => {
    try {
      const state = (getState() as RootState);
      const userEnergy = state?.profile.userData?.energy as number;
      const maxEnergy = state?.settings.settings?.max_energy || 100;
      if (!energy.replace) {
        const resultEnergy = (userEnergy || 0) + energy.energy;
        return resultEnergy > maxEnergy ? userEnergy : resultEnergy;
      }
      return energy.energy;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const updateTGProfile = createAsyncThunk<
    UpdateTGProfileData,
    UpdateTGProfileData
>(
  'profile/tg_update',
  async (profile, { rejectWithValue }) => {
    try {
      return profile;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
export const updateUserData = createAsyncThunk<
    UpdateUserData,
    UpdateUserData
>(
  'profile/update',
  async (profile, { rejectWithValue }) => {
    try {
      return profile;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const getMe = createAsyncThunk<
    ProfileResponseT
>(
  'profile/get',
  async (profile, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get('users/me');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);

export const createUser = createAsyncThunk<
    ProfileResponseT,
    CreateUserT
>(
  'profile/create',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post('users', userData);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err);
    }
  },
);
