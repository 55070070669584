// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AviatorStartResponse, IAviatorStore } from '../../../types';
import { startAviator } from './asyncActions';

// Types

const initialState: IAviatorStore = {
  aviatorData: null,
  error: null,
  loading: false,
};

export const aviatorSlice = createSlice({
  name: 'aviator',
  initialState,
  reducers: {
    clearErrorMessage(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        startAviator.fulfilled,
        (state, action: PayloadAction<AviatorStartResponse>) => {
          const source: IAviatorStore = {
            loading: false,
            aviatorData: action.payload,
            error: null,
          };
          Object.assign(state, source);
        },
      );
  },
});

export const { clearErrorMessage } = aviatorSlice.actions;
export const AviatorReducer = aviatorSlice.reducer;
