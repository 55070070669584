import * as React from 'react';
import {
  useRef, useState,
} from 'react';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import Button from '../Button';
import { useWebSocketContext } from '../../providers/WebSocketProvider';
import { AviatorGameStartedData, AviatorGameWSMessages } from '../../providers/WebSocketProvider/types';
import { updateProfileCoins, updateUserData } from '../../store/reducers/profile/asyncActions';
import { useAppDispatch } from '../../store';
import astronautSolo from '../../assets/animations/astronaut_solo.json';
import rocketSolo from '../../assets/animations/rocket_solo.json';
import itemsAnimation from '../../assets/animations/items_solo.json';
import bgAnimation from '../../assets/animations/bg_solo.json';
import { ReactComponent as Coin } from '../../assets/svg/coin.svg';
import { useCountDown } from '../../hooks/useCoundown';
import { numberWithCommas } from '../../helpers';

function AviatorRocket() {
  const {
    startGame, webSocket, stopGame,
  } = useWebSocketContext();
  const initialTimerSeconds = 5;
  const dispatch = useAppDispatch();
  const rocketRef = useRef<LottieRefCurrentProps | null>(null);
  const astronautRef = useRef<LottieRefCurrentProps | null>(null);
  const itemsRef = useRef<LottieRefCurrentProps | null>(null);
  const backgroundRef = useRef<LottieRefCurrentProps | null>(null);
  const bet = 1000; // TODO: get bet from settings
  const [gameStatus, setGameStatus] = useState<'won' | 'lose' | 'running' | 'bet' | 'starting' | 'stoppedByUser'>('bet');
  const [gameData, setGameData] = useState<AviatorGameStartedData['data'] | null>(null);
  const [multiplier, setMultiplier] = useState<number>(1);
  const [rocketValue, setRocketValue] = useState<number | null>(null);
  const { formatedSecond, restart: restartCounter } = useCountDown(initialTimerSeconds);
  if (webSocket) {
    webSocket.onmessage = (event: MessageEvent<string>) => {
      const message: AviatorGameWSMessages = JSON.parse(event.data);
      if (message.type === 'start') {
        dispatch(updateUserData(message.data.user));
        handleAnimationsStart();
        setGameData(message.data);
        setGameStatus('running');
      }
      if (message.type === 'progress') {
        setRocketValue(message.data.currentValue);
      } else if (message.type === 'result') {
        dispatch(updateUserData(message.data.user));
        handleStop();
        if (message.data.isWinner) {
          setGameStatus('won');
          dispatch(updateProfileCoins(message.data.user.coins));
        } else {
          setGameStatus('lose');
        }
      } else if (message.type === 'error') {
        window.console.log('error', message);
      }
    };
  }

  const handleAnimationsStart = () => {
    rocketRef.current?.play();
    astronautRef.current?.play();
    itemsRef.current?.play();
    backgroundRef.current?.play();
  };
  const handleAnimationsStop = () => {
    rocketRef.current?.pause();
    astronautRef.current?.pause();
    itemsRef.current?.pause();
    backgroundRef.current?.pause();
  };
  const handleStart = () => {
    setGameStatus('starting');
    restartCounter(5);
    startGame(multiplier || undefined);
  };
  const handleStop = () => {
    handleAnimationsStop();
    if (gameData) {
      setGameStatus('stoppedByUser');
      stopGame({ aviatorId: gameData.aviatorId, hash: gameData.hash });
    }
  };

  const handleAddMultiplier = () => {
    setMultiplier(Number((multiplier + 0.1).toFixed(1)));
  };
  const handleSubtractMultiplier = () => {
    const calculatedMultiplier = Number((multiplier - 0.1).toFixed(1));
    if (calculatedMultiplier >= 0) {
      setMultiplier(calculatedMultiplier);
    }
  };
  const handleCloseGame = () => {
    setGameStatus('bet');
    restartCounter(initialTimerSeconds);
  };
  return (
    <div className="absolute h-screen w-screen top-0">
      <div className="z-20 absolute h-full w-full flex items-center justify-center translate-y-[-25%]">
        <div className="flex flex-col">
          {gameStatus === 'starting'
          && <div className="text-xs">Start in</div>}
          {gameStatus === 'running'
             && (
             <div className="text-5xl">
               {rocketValue}
               x
             </div>
             )}
          {gameStatus === 'starting'
          && <div className="text-5xl">{formatedSecond}</div>}
          <div className="text-sm flex gap-1 mt-1 z-40 text-black-text">
            <span>Current cashout</span>
            <span>{numberWithCommas(bet * (rocketValue || 1))}</span>
            <span>
              <Coin />
            </span>
          </div>
        </div>
      </div>
      <div className="absolute h-screen w-screen">
        <div className="absolute z-10 h-full bottom-[33%]">
          <Lottie
            animationData={astronautSolo}
            loop
            lottieRef={astronautRef}
            autoplay={false}
            className="h-full"
            rendererSettings={{
              preserveAspectRatio: 'xMaxYMax',
            }}
          />
        </div>
        <div className="absolute z-10 h-full bottom-[33%]">
          <Lottie
            animationData={rocketSolo}
            loop
            lottieRef={rocketRef}
            autoplay={false}
            className="h-full"
            rendererSettings={{
              preserveAspectRatio: 'xMaxYMax',
            }}
          />
        </div>
        <div className="absolute z-10 h-full">
          <Lottie
            animationData={itemsAnimation}
            loop
            autoplay={false}
            lottieRef={itemsRef}
            className="h-full"
            rendererSettings={{
              preserveAspectRatio: 'xMaxYMax',
            }}
          />
        </div>
        <div className="absolute w-fit h-full">
          <Lottie
            animationData={bgAnimation}
            loop
            autoplay={false}
            lottieRef={backgroundRef}
            className="h-full"
            rendererSettings={{
              preserveAspectRatio: 'xMaxYMax slice',
            }}
          />
        </div>
      </div>
      <div className="z-20 relative h-full w-full flex items-end justify-center">
        <div className="h-fit mb-[120px] w-full flex justify-center flex-col items-center max-w-[80vw] gap-2">
          {gameStatus === 'won'
              && (
                <div className="bg-white bg-opacity-[0.08] rounded-10 p-1 px-2 flex justify-center items-center gap-1 w-full border-[#FFFFFF14] border-[1px]">
                  <span>You won</span>
                  <span>{numberWithCommas(bet * (rocketValue || 1))}</span>
                  <span>
                    <Coin />
                  </span>
                </div>
              )}
          {gameStatus === 'lose'
              && (
                <div className="bg-white bg-opacity-[0.08] rounded-10 p-1 px-2 flex justify-center items-center gap-1 w-full border-[#FFFFFF14] border-[1px]">
                  <span>You Lost</span>
                </div>
              )}
          {(gameStatus === 'won' || gameStatus === 'lose')
              && (
              <Button
                className="bg-white w-full text-black-text border-[#FFFFFF14] border-[1px]"
                onClick={handleCloseGame}
              >
                Close
              </Button>
              )}
          {(gameStatus === 'bet')
              && (
              <Button
                className="bg-white w-full text-black-text border-[#FFFFFF14] border-[1px]"
                onClick={handleStart}
              >
                Start
              </Button>
              )}
          {gameStatus === 'running'
          && (
          <div className="bg-white bg-opacity-[0.08] rounded-10 p-1 px-2 flex justify-center items-center gap-1 w-full border-[#FFFFFF14] border-[1px]">
            <span>Cash out</span>
            <span>{numberWithCommas(bet * (rocketValue || 1))}</span>
            <span>
              <Coin />
            </span>
          </div>
          )}
          {(gameStatus === 'bet' || gameStatus === 'running')
              && (
              <div className="bg-white bg-opacity-[0.08] rounded-10 p-1 px-2 flex gap-2 w-full justify-between border-[#FFFFFF14] border-[1px]">
                {gameStatus === 'bet'
            && (
            <Button
              onClick={handleSubtractMultiplier}
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px]"
            >
              -
            </Button>
            )}
                <div className="p-0 w-full">
                  <div className="text-gray-2 text-[10px] p-0">Auto withdrawal</div>
                  <div className="text-base p-0">
                    {multiplier}
                    x
                  </div>
                </div>
                {gameStatus === 'bet'
            && (
            <Button
              onClick={handleAddMultiplier}
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px]"
            >
              +
            </Button>
            )}
              </div>
              )}
          {gameStatus === 'running'
             && (
             <Button
               onClick={() => handleStop()}
               className="w-full bg-gradient-to-r from-[#EFAF00] to-[#E47513] text-[#701E00]"
             >
               Cash out
             </Button>
             )}
          {gameStatus === 'bet'
          && (
          <div className="flex gap-2 w-full justify-between">
            <Button
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(1.1)}
            >
              1.1x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(1.5)}
            >
              1.5x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(2)}
            >
              2x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(2.5)}
            >
              2.5x
            </Button>
            <Button
              className="bg-white bg-opacity-[0.08] text-white border-[#FFFFFF14] border-[1px] w-full py-2"
              onClick={() => setMultiplier(5)}
            >
              5x
            </Button>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AviatorRocket;
