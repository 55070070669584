import React from 'react';
import { useCountDown } from '../../hooks/useCoundown';
import { useAppSelector } from '../../store';
import { SettingsResponse } from '../../store/reducers/settings/types';

type RefilCountdownProps = {
    secondsFromLastRefill: number
}
function RefilCountdown({ secondsFromLastRefill }: RefilCountdownProps) {
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const { formatedSecond, formatedMinutes } = useCountDown((appSettings as SettingsResponse).daily_energy_refill_refresh_seconds - secondsFromLastRefill);
  return (
    <div className="flex gap-1">
      <div className="bg-white bg-opacity-10 p-2 rounded-10">{formatedMinutes[0]}</div>
      <div className="bg-white bg-opacity-10 p-2 rounded-10">{formatedMinutes[1]}</div>
      <div className="p-2 rounded-10">:</div>
      <div className="bg-white bg-opacity-10 p-2 rounded-10">{formatedSecond[0]}</div>
      <div className="bg-white bg-opacity-10 p-2 rounded-10">{formatedSecond[1]}</div>
    </div>
  );
}
export default RefilCountdown;
