import {
  ReactNode, useEffect, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { getMe, updateTGProfile } from '../../store/reducers/profile/asyncActions';
import Loader from '../../Components/Loader';
import { getQuests } from '../../store/reducers/quests/asyncActions';
import { getReferrals } from '../../store/reducers/referrals/asyncActions';
import { getSettings, getVersion } from '../../store/reducers/settings/asyncActions';
import { authenticateUser, refreshAuthToken } from '../../store/reducers/auth/asyncActions';
import Modal from '../../Components/Modal';
import { getTopPlayers } from '../../store/reducers/leaderboard/asyncActions';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version: appVersion } = require('../../../package.json');

interface AppWrapperProps {
    children: ReactNode
}

const tg = window?.Telegram.WebApp;

function AppWrapper({ children }: AppWrapperProps) {
  const dispatch = useAppDispatch();
  const {
    loading: profileLoading,
  } = useAppSelector(({ profile }) => profile);
  const {
    loading: questsLoading,
  } = useAppSelector(({ quests }) => quests);
  const {
    version: beVersion, loading: settingsLoading,
  } = useAppSelector(({ settings }) => settings);
  const { error: authError, loading: authLoading } = useAppSelector(({ auth }) => auth);

  const [fakeLoading, setFakeLoading] = useState<boolean>(true);

  const handleAuth = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      await dispatch(refreshAuthToken());
    } else {
      await dispatch(authenticateUser(tg.initData));
    }
  };

  const initialLoadingData = async () => { // initial data loading to get all pages data on loading screen
    await Promise.all([
      dispatch(getMe()),
      dispatch(getVersion()),
      dispatch(getSettings()),
      dispatch(getQuests()),
      dispatch(getReferrals()),
      dispatch(getTopPlayers()),
    ]);
  };

  useEffect(() => {
    (async () => {
      await handleAuth();
      await initialLoadingData();
    })();
  }, []);

  useEffect(() => {
    dispatch(updateTGProfile(tg.initDataUnsafe));
    console.log(tg, 'tg');
  }, [tg.initDataUnsafe]);

  useEffect(() => {
    if (beVersion) {
      window.console.log({
        BE_Version: beVersion,
        FE_Version: appVersion,
      }, 'app version');
    }
  }, [beVersion]);

  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false);
    }, 3000);
  }, []);

  const handleReload = () => {
    tg?.close();
  };

  if (authError) {
    return <Modal message={authError} onReload={handleReload} />;
  }

  if (profileLoading || settingsLoading || questsLoading || fakeLoading || authLoading) {
    return <Loader />;
  }
  return <div>{children}</div>;
}

export default AppWrapper;
