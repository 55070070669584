import React, { ReactNode } from 'react';
import { ReactComponent as CloseIco } from '../../assets/svg/close.svg';
import Button from '../Button';

const openClassNames = {
  right: 'translate-x-0',
  left: 'translate-x-0',
  top: 'translate-y-0',
  bottom: 'translate-y-0',
};

const closeClassNames = {
  right: 'translate-x-full',
  left: '-translate-x-full',
  top: '-translate-y-full',
  bottom: 'translate-y-full',
};

const classNames = {
  right: 'inset-y-0 right-0',
  left: 'inset-y-0 left-0',
  top: 'inset-x-0 top-0',
  bottom: 'inset-x-0 bottom-0',
};

type DrawerProps = {
    side: 'right' | 'left' |'top' | 'bottom'
    open: boolean
    onClose: () => void
    children: ReactNode
    wrapperClassName?: string
    closeStroke?: string
    hideClose?: boolean
}
function Drawer({
  open, onClose, side = 'right', children, wrapperClassName, closeStroke, hideClose,
}: DrawerProps) {
  return (
    <div
      id={`dialog-${side}`}
      className="relative z-50"
      aria-labelledby="slide-over"
      aria-modal="true"
      onClick={onClose}
    >
      <div
        className={`fixed inset-0 bg-black-main bg-opacity-80 transition-all ${open ? 'opacity-100 duration-500 ease-in-out visible' : 'opacity-0 duration-500 ease-in-out invisible'}`}
      />
      <div className={open ? 'fixed inset-0 overflow-hidden' : ''}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={`pointer-events-none fixed max-w-full ${classNames[side]}`}
          >
            <div
              className={`pointer-events-auto relative w-full h-full transform transition ease-in-out duration-500
              ${open ? openClassNames[side] : closeClassNames[side]}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div
                className={wrapperClassName || 'flex flex-col overflow-y-scroll h-60 shadow-xl rounded-t-[20px] bg-black-modal-bg z-[99999] relative stroke-[#5F6168]'}
              >
                {!hideClose
                && <Button pureClass className="text-end absolute right-4 top-3 cursor-pointer" onClick={onClose}><CloseIco className={`stroke-${closeStroke || '[#5F6168]'}`} /></Button>}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Drawer;
