import { SettingsResponse } from '../store/reducers/settings/types';
import { ProfileResponseT } from '../types';

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getSecondsBetweenDates(startDate: Date, endDate: Date) {
  const diff = endDate.getTime() - startDate.getTime();
  return Math.round(diff / 1000);
}

export const formatNumbers = (number: number) => Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 1,
}).format(number);

export const getNextLeagueCoins = (leagues: SettingsResponse['leagues'], userLeague: ProfileResponseT['league']['name']) => {
  const currentLeagueIndex = leagues?.findIndex((league) => league.name === userLeague) || 0;
  const nextLeagueIndex: number = currentLeagueIndex === (leagues.length + 1) ? leagues.length : currentLeagueIndex + 1;
  return leagues[nextLeagueIndex].from || 0;
};
