import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import RoutesComponent from './Router';
import { store } from './store';
import Loader from './Components/Loader';
import AppWrapper from './Layouts/AppWrapper';
import WebSocketProvider from './providers/WebSocketProvider';

const tg = window?.Telegram.WebApp;
function App() {
  useEffect(() => {
    tg.ready();
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          {tg.initDataUnsafe // wait for telegram initialization
            ? (
              <AppWrapper>
                <WebSocketProvider>
                  <RoutesComponent />
                </WebSocketProvider>
              </AppWrapper>
            )
            : <Loader />}
        </Router>
      </Provider>
    </div>
  );
}

export default App;
