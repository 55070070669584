function Store() {
  return (
    <div>
      <h1>Store</h1>
      <h2>Hic sunt dracones</h2>
    </div>
  );
}

export default Store;
