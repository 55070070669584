import AviatorRocket from '../../Components/AviatorRocket';

function HomePage() {
  return (
    <div>
      <AviatorRocket />
    </div>
  );
}

export default HomePage;
