import React from 'react';
import AvatarPlaceholder from '../../../assets/images/avatar-placeholder-v2.svg';
import { ReactComponent as Coin } from '../../../assets/svg/coin.svg';
import { ReferralT } from '../../../store/reducers/referrals/types';

type FriendsCardProps = {
  referral: ReferralT
}
function FriendCard({ referral }: FriendsCardProps) {
  // TODO: temporarily removed the implementation of showing the real avatar, show only the placeholder

  // const [imgSrc, setImgSrc] = useState(referral?.avatar_url && process.env.REACT_APP_API_PATH + referral.avatar_url);
  // const [hasError, setHasError] = useState(false);

  // const handleImageError = () => {
  //   setImgSrc(AvatarPlaceholder);
  //   setHasError(true);
  // };

  return (
    <div className="bg-black-light-bg p-3 rounded-10 flex gap-3 w-full max-h-[100px]">
      <div className="basis-1/2 flex flex-col gap-[6px] overflow-hidden justify-between">
        <img src={AvatarPlaceholder} alt="avatar" className="rounded max-w-[18px] max-h-[18px] h-fit" />
        <div className="text-xs font-bold line-normal text-ellipsis whitespace-nowrap">{referral.username}</div>
      </div>
      <div className="basis-1/2 flex flex-col items-end justify-between gap-[6px]">
        <div className="text-gray-300 text-xs">
          Total profit
        </div>
        <div className="text-lg flex items-center gap-2 line-normal font-bold">
          {referral.coins || 0}
          <Coin />
        </div>
      </div>
    </div>
  );
}
export default FriendCard;
