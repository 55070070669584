import { useEffect } from 'react';
import ProgressBar from '../../ProgressBar';
import { useAppDispatch, useAppSelector } from '../../../store';
import { ReactComponent as Energy } from '../../../assets/svg/Energy.svg';
import { updateProfileEnergy } from '../../../store/reducers/profile/asyncActions';

function EnergyInfo() {
  const dispatch = useAppDispatch();
  const { settings: userSettings } = useAppSelector(
    ({ settings }) => settings,
  );
  const { userData } = useAppSelector(
    ({ profile }) => profile,
  );

  useEffect(() => {
    const energyFillInterval = setInterval(() => {
      if (userSettings?.energy_recovery_per_second) {
        dispatch(updateProfileEnergy({ energy: userSettings.energy_recovery_per_second, replace: false }));
      }
    }, 1000);
    return () => clearInterval(energyFillInterval);
  }, [userSettings?.energy_recovery_per_second]);
  return (
    <div className="flex items-center gap-1">
      <Energy />
      <div>
        <div className="flex text-[10px]">
          {Math.floor(userData?.energy || 0)}
          <span className="text-gray-2 mx-1">/</span>
          {userSettings?.max_energy}
        </div>
        <ProgressBar
          size="small"
          goal={userSettings?.max_energy || 100}
          currentValue={userData?.energy || 0}
          elementWidth="13px"
          type="energy"
          hideLine
        />
      </div>
    </div>
  );
}

export default EnergyInfo;
