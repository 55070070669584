import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Friends } from '../../assets/svg/friends.svg';
import { ReactComponent as Checklist } from '../../assets/svg/checklist.svg';
import { ReactComponent as Trophy } from '../../assets/svg/trophy.svg';
import { ReactComponent as Basket } from '../../assets/svg/basket.svg';
import { ReactComponent as Game } from '../../assets/svg/game.svg';
import { MenuItemsT } from '../../types';

const MenuItems: MenuItemsT[] = [
  {
    label: 'Games',
    link: '/',
    ico: <Game className="fill-inherit" />,
  },
  {
    label: 'Friends',
    link: 'friends',
    ico: <Friends className="fill-inherit" />,
  },
  {
    label: 'Quests',
    link: 'quests',
    ico: <Checklist className="fill-inherit" />,
  },
  {
    label: 'Top players',
    link: 'leaderbord',
    ico: <Trophy className="fill-inherit" />,
  },
  {
    label: 'Store',
    link: 'store',
    ico: <Basket className="fill-inherit" />,
  },
];
function Menu() {
  const { pathname } = useLocation();
  return (
    <div className="absolute bottom-2 mx-auto flex justify-center w-full max-h-[80px]">
      <ul className="mb-4 mx-auto rounded-10 flex gap-2 justify-around bg-black-light-bg p-1 z-40">
        {MenuItems.map((menuItem) => {
          const path = pathname.substring(1);
          let isActive = path.includes(menuItem.link);
          if (menuItem.link === '/' && pathname === '/') {
            isActive = true;
          }
          return (
            <li className={`${isActive && 'bg-black-dark-bg'} rounded p-2`} key={menuItem.label}>
              <Link to={menuItem.link} className="cursor-pointer px-1 flex flex-col items-center">
                <span className={`${isActive ? 'fill-green-main' : 'fill-gray-300'}`}>
                  {menuItem.ico}
                </span>
                <span className="text-12 text-nowrap">
                  {menuItem.label}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
export default Menu;
