type ProgressBarProps = {
  size?: 'small' | 'default'
  currentValue: number
  goal: number
  elementWidth?: string
  type?: 'main' | 'energy'
  hideLine?: boolean
}
function ProgressBar({
  size = 'default', currentValue, goal, elementWidth, type = 'main', hideLine,
}: ProgressBarProps) {
  const currentPercentage = (currentValue / goal) * 100;
  const completedPercentage = Math.floor(currentPercentage > 100 ? 100 : currentPercentage); // maximum value should be 100
  const positionClass = `before:left-[${completedPercentage}%]`;
  return (
    <div className={`flex gap-[1px] border 
    ${type === 'main' ? 'border-green-main border-opacity-10' : 'border-blue-progressbar-border border-opacity-15'}
    p-[1px] pl-0 rounded 
    ${!hideLine && "before:content[''] before:w-0.5"} 
    ${size === 'small' ? 'before:h-[8px]' : 'before:h-[12px]'} before:relative before:bg-gray-200 ${positionClass}
    `}
    >
      <div
        className={`w-1/5 ${size === 'small' ? 'h-[8px]' : 'h-[12px]'} 
        ${type === 'main' ? 'bg-green-main bg-opacity-10' : 'bg-blue-progressbar-bg bg-opacity-10'}
         rounded`}
        style={{
          background: (completedPercentage >= 20) ? completedCellBGStyles[type] : undefined,
          width: elementWidth,
        }}
      />
      <div
        className={`w-1/5 ${size === 'small' ? 'h-[8px]' : 'h-[12px]'} bg-green-main bg-opacity-10 rounded`}
        style={{
          background: (completedPercentage >= 40) ? completedCellBGStyles[type] : undefined,
          width: elementWidth,
        }}
      />
      <div
        className={`w-1/5 ${size === 'small' ? 'h-[8px]' : 'h-[12px]'} bg-green-main bg-opacity-10 rounded`}
        style={{
          background: (completedPercentage >= 60) ? completedCellBGStyles[type] : undefined,
          width: elementWidth,
        }}
      />
      <div
        className={`w-1/5 ${size === 'small' ? 'h-[8px]' : 'h-[12px]'} bg-green-main bg-opacity-10 rounded`}
        style={{
          background: (completedPercentage >= 80) ? completedCellBGStyles[type] : undefined,
          width: elementWidth,
        }}
      />
      <div
        className={`w-1/5 ${size === 'small' ? 'h-[8px]' : 'h-[12px]'} bg-green-main bg-opacity-10 rounded`}
        style={{
          background: (completedPercentage >= 100) ? completedCellBGStyles[type] : undefined,
          width: elementWidth,
        }}
      />
    </div>
  );
}

export default ProgressBar;
const completedCellBGStyles = {
  main: `repeating-linear-gradient(
                -55deg,
                #14D8A6,
                #24C29A 2px,
                #24C29A 2px,
                #24C29A 5px
              )`,
  energy: `repeating-linear-gradient(
                -55deg,
                #00AFE6,
                #00AFE6 2px,
                #0BC5FF 2px,
                #0BC5FF 5px
              )`,
} as const;
