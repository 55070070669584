// Actions
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CustomErrorPayload,
  IProfileStore,
  ProfileResponseT,
  UpdateProfileCoins,
  UpdateTGProfileData,
  UpdateProfileEnergyResponse,
} from '../../../types';
import {
  createUser, getMe, updateTGProfile, updateProfileCoins, updateProfileEnergy, updateUserData,
} from './asyncActions';

// Types

const initialState: IProfileStore = {
  tgProfileData: null,
  userData: null,
  error: null,
  loading: true,
  userCreationPending: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getMe.fulfilled,
        (state, action: PayloadAction<ProfileResponseT>) => {
          state.userData = action.payload;
          state.error = null;
          state.loading = false;
          state.userCreationPending = false;
        },
      )
      .addCase(
        updateUserData.fulfilled,
        (state, action: PayloadAction<ProfileResponseT>) => {
          state.userData = action.payload;
        },
      )
      .addCase(getMe.rejected, (state, action) => {
        const errorPayload = action.payload as CustomErrorPayload;
        state.error = errorPayload.response?.data?.message;
        state.loading = false;
      })
      .addCase(
        createUser.fulfilled,
        (state, action: PayloadAction<ProfileResponseT>) => {
          state.userData = action.payload;
          state.error = null;
          state.userCreationPending = false;
        },
      )
      .addCase(
        createUser.rejected,
        (state, action) => {
          const errorPayload = action.payload as CustomErrorPayload;
          state.error = errorPayload.response?.data?.message;
          state.userCreationPending = false;
        },
      )
      .addCase(
        createUser.pending,
        (state) => {
          state.userCreationPending = true;
        },
      )
      .addCase(
        updateProfileCoins.fulfilled,
        (state, action: PayloadAction<UpdateProfileCoins>) => {
          if (state.userData?.coins) {
            state.userData.coins = action.payload;
          }
        },
      )
      .addCase(
        updateTGProfile.fulfilled,
        (state, action: PayloadAction<UpdateTGProfileData>) => {
          state.tgProfileData = action.payload;
        },
      )
      .addCase(
        updateProfileEnergy.fulfilled,
        (state, action: PayloadAction<UpdateProfileEnergyResponse>) => {
          if (state.userData?.energy) {
            state.userData.energy = action.payload;
          }
        },
      );
  },
});

export const ProfileReducer = profileSlice.reducer;
