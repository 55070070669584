import React, { useMemo } from 'react';
import Energy from '../../../assets/images/energy.png';
import { useAppSelector } from '../../../store';
import { SettingsResponse } from '../../../store/reducers/settings/types';
import { TQuestsResponse } from '../../../types';
import { getSecondsBetweenDates } from '../../../helpers';
import EnergyCardCountdown from './EnergyCardCountdown';

type RefillCardProps = {
    handleEnergyClick: () => void
}
function RefillCard({ handleEnergyClick }: RefillCardProps) {
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const completedEnergyQuests = useMemo(() => questsList?.daily_energy_refill?.filter((el) => el.progress?.completed_at).length, [questsList?.daily_energy_refill]);
  const totalEnergyQuests = questsList?.daily_energy_refill?.length;

  const lastCompletedRefill = [...(questsList as TQuestsResponse).daily_energy_refill].find((el) => el.progress
        && el.progress.started_at
        && !el.progress.completed_at);
  const secondsFromLastRefill = getSecondsBetweenDates(new Date(lastCompletedRefill?.progress?.started_at || ''), new Date());
  const countdownTime = (appSettings as SettingsResponse).daily_energy_refill_refresh_seconds - secondsFromLastRefill;
  return (
    <div className="bg-gradient-to-r from-blue-gradient-1 to-blue-gradient-2 rounded-10 w-full" onClick={handleEnergyClick}>
      <div className="bg-blueStar bg-yellowStarSize bg-no-repeat bg-yellowStarPosition flex justify-between  border-none flex gap-2 text-left  p-3 px-5">
        <div>
          <div className="text-sm">
            Energy Refill
          </div>
          <div className="text-xs">
            {completedEnergyQuests}
            /
            {totalEnergyQuests}
            {(countdownTime > 0)
              ? (
                <EnergyCardCountdown secondsFromLastRefill={secondsFromLastRefill} dailyEnergyRefillRefreshSeconds={(appSettings as SettingsResponse).daily_energy_refill_refresh_seconds} />
              ) : null}
          </div>
        </div>
        <div>
          <img
            className="m-auto max-h-[40px]"
            src={Energy}
            alt="energy"
          />
        </div>
      </div>
    </div>
  );
}

export default RefillCard;
