import React, { useMemo } from 'react';
import Energy from '../../../assets/images/energy.png';
import Button from '../../../Components/Button';
import { useAppSelector } from '../../../store';
import { TQuestsResponse } from '../../../types';
import { getSecondsBetweenDates } from '../../../helpers';
import RefilCountdown from '../../../Components/RefilCountdown';
import { SettingsResponse } from '../../../store/reducers/settings/types';

type EnergyDrawerContentProps = {
    handleEnergyFill: () => void
}
function EnergyDrawerContent({ handleEnergyFill }: EnergyDrawerContentProps) {
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );
  const {
    settings: appSettings,
  } = useAppSelector(({ settings }) => settings);
  const completedEnergyQuests = useMemo(() => questsList?.daily_energy_refill?.filter((el) => el.progress?.completed_at).length, [questsList?.daily_energy_refill]);
  const totalEnergyQuests = questsList?.daily_energy_refill?.length;

  const lastCompletedRefill = [...(questsList as TQuestsResponse).daily_energy_refill].find((el) => el.progress
        && el.progress.started_at
        && !el.progress.completed_at);
  const secondsFromLastRefill = getSecondsBetweenDates(new Date(lastCompletedRefill?.progress?.started_at || ''), new Date());
  const countdownTime = (appSettings as SettingsResponse).daily_energy_refill_refresh_seconds - secondsFromLastRefill;
  return (
    <div className="bg-dailyEnergyBgStar bg-no-repeat bg-energyBgStarPosition h-full">
      <div className="mt-[10%]">
        <img
          className="m-auto h-[100px]"
          src={Energy}
          alt="energy"
        />
      </div>
      <div className="text-lg mt-4">Energy fill</div>
      <div className="text-xs text-gray-2 mt-2">Checkin every day to stay engagedwith our project.</div>
      <div className="text-sm mt-2">
        {completedEnergyQuests}
        {' '}
        <span className="text-gray-2">/</span>
        {' '}
        {totalEnergyQuests}
      </div>
      {countdownTime < 0
        ? (
          <div className="px-4">
            <Button className="mt-2 w-full" onClick={handleEnergyFill}>Fill</Button>
          </div>
        )
        : (
          <div className="flex justify-center items-center mt-2">
            <RefilCountdown secondsFromLastRefill={secondsFromLastRefill} />
          </div>
        )}
      <div className="bg-white rounded h-2 w-1/2 m-auto mt-4" />
    </div>
  );
}

export default EnergyDrawerContent;
