import React, { useMemo, useState } from 'react';
import { QuestT, TQuestsResponse } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store';
import { finishQuest, startQuest } from '../../store/reducers/quests/asyncActions';
import { ReactComponent as CircleCheck } from '../../assets/svg/circleCheck.svg';
import QuestDrawerContent from './QuestDrawerContent';
import Drawer from '../../Components/Drawer';
import DailyQuest from '../../Components/DailyQuest';
import Button from '../../Components/Button';
import { ReactComponent as Coin } from '../../assets/svg/coin.svg';
import { ReactComponent as Clock } from '../../assets/svg/clock.svg';
import RefillCard from './RefillCard';
import EnergyDrawerContent from './EnergyDrawerContent';
import DailyCard from './DailyCard';
import { ClaimedButton, CompleteTheTaskButton } from '../../Components/QuestButtons';

function QuestPage() {
  const dispatch = useAppDispatch();
  const { questsList } = useAppSelector(
    ({ quests }) => quests,
  );
  const [selectedQuest, setSelectedQuest] = useState<null | QuestT>(null);
  const [invalidQuests, setInvalidQuests] = useState<number[]>([]);

  const [openDailyDrawer, setOpenDailyDrawer] = useState<boolean>(false);
  const [openEnergyDrawer, setOpenEnergyDrawer] = useState<boolean>(false);
  const sortedQuests = useMemo(() => {
    const tempList: TQuestsResponse = {
      daily: [],
      social: [],
      referral: [],
      daily_energy_refill: [],
    };
    Object.keys(questsList || []).forEach((key: keyof TQuestsResponse) => {
      const sortedList = [...(questsList as TQuestsResponse)[key]].sort((el) => (el.progress?.completed_at ? 0 : -1)) || [];
      Object.assign(tempList, { [key]: sortedList });
    });
    return tempList;
  }, [questsList]);
  const completedDailyQuests = questsList?.daily?.filter((el) => el.progress?.completed_at).length;
  const isTodayQuestCompleted = useMemo(() => ![...(questsList?.daily || [])].filter((quest) => quest.progress?.started_at && !quest.progress.completed_at).length, [questsList?.daily]);
  const handleDailyClick = () => {
    setOpenDailyDrawer(true);
  };
  const handleEnergyClick = () => {
    setOpenEnergyDrawer(true);
  };
  const handleDailyClaim = () => {
    const dailyQuestId = questsList?.daily.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at)?.id;
    if (dailyQuestId) {
      dispatch(finishQuest({ id: dailyQuestId }));
    }
  };
  const isDailyQuestAvailable = questsList?.daily.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at);
  const handleEnergyFill = () => {
    const questId = questsList?.daily_energy_refill.find((dailyQuest) => dailyQuest.progress?.started_at && !dailyQuest.progress?.completed_at)?.id;
    if (questId) {
      dispatch(finishQuest({ id: questId }));
    }
  };
  const handleQuestStart = async (quest: QuestT) => {
    if (quest.url) {
      window.open(quest.url, '_blank');
    }
    const resp = await dispatch(startQuest({ id: quest.id }));
    if (resp?.payload?.quest) {
      setSelectedQuest(resp.payload.quest);
    }
  };
  const handleQuestCheck = async (quest: QuestT) => {
    const resp = await dispatch(finishQuest({ id: quest.id }));
    if (resp?.error) {
      const temp = [...invalidQuests, quest.id];
      setInvalidQuests(temp);
    } else {
      const tempIds = [...invalidQuests];
      tempIds.filter((id) => id !== quest.id);
      setInvalidQuests(tempIds);
    }
  };
  return (
    <div className="px-2 h-full flex flex-col">
      <div className="flex gap-2 justify-between px-2">
        <DailyCard handleDailyClick={handleDailyClick} completedDailyQuests={completedDailyQuests || 0} totalDailyQuests={sortedQuests.daily.length} isTodayQuestCompleted={isTodayQuestCompleted} />
        <RefillCard handleEnergyClick={handleEnergyClick} />
      </div>
      <div
        className="w-full overflow-y-scroll bg-black-modal-bg shadow-xl rounded-t-[20px] text-white mt-4 h-full"
      >
        <div className="bg-black-light-bg w-1/4 h-[3px] mt-2 m-auto" />
        <div className="p-3 h-full">
          <QuestDrawerContent
            quests={sortedQuests}
            invalidQuests={invalidQuests}
            setSelectedQuest={setSelectedQuest}
            handleQuestCheck={handleQuestCheck}
            handleQuestStart={handleQuestStart}
          />
        </div>
      </div>
      <Drawer
        side="bottom"
        open={openDailyDrawer}
        onClose={() => setOpenDailyDrawer(false)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-80 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#B73B20] to-[#821220] z-[99999] relative stroke-gray-10"
      >
        <div className="bg-dailyBgStar bg-no-repeat h-full">
          <div className="mt-2 px-4">
            <div className="text-start">
              <div className="text-lg">
                Daily reward
              </div>
              <div className="text-sm">
                Checkin every day to stay engagedwith our project.
              </div>
            </div>
            <div className="flex gap-1 flex-wrap mt-2">
              {[...(questsList?.daily || [])].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })).map((dailyQuest) => <DailyQuest quest={dailyQuest} key={dailyQuest.id} />)}
            </div>
            {isDailyQuestAvailable
              ? <Button className="mt-4 w-full py-2" onClick={handleDailyClaim}>Claim</Button>
              : (
                <Button className="mt-4 w-full py-2 bg-green-text bg-opacity-15 rounded-10 text-green-text flex items-center justify-center gap-1" pureClass>
                  <CircleCheck stroke="#1FD969" />
                  Claimed
                </Button>
              )}
            <div className="bg-white rounded h-2 w-1/2 m-auto mt-3" />
          </div>
        </div>
      </Drawer>
      <Drawer
        side="bottom"
        open={!!selectedQuest}
        onClose={() => setSelectedQuest(null)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-60 shadow-xl rounded-t-[20px] bg-black-modal-bg z-[99999] relative stroke-gray-10"
      >
        <div className="text-start px-4 mt-2 h-full">
          <div className="text-lg">{selectedQuest?.name}</div>
          <div className="text-sm text-gray-2">{selectedQuest?.description}</div>
          <div className="text-sm text-gray-2 flex gap-2 bg-black-light-bg rounded-10 p-2 mt-4 items-center">
            Reward
            <span className="text-white">
              {selectedQuest?.rewards}
            </span>
            <Coin stroke="none" />
          </div>
          {selectedQuest?.type !== 'referral'
              && (
              <div className="text-sm text-gray-2 flex gap-2 mt-4">
                <Clock />
                {'Completion time: <1 minutes'}
              </div>
              )}
          {invalidQuests.includes(selectedQuest?.id as number)
              && (
              <div className="text-start text-xs min-w-[200px] text-red-error">
                The task was not completed.
                <br />
                Try again in 2 minutes.
              </div>
              )}
          {/* eslint-disable-next-line no-nested-ternary */}
          {selectedQuest?.progress?.completed_at
            ? (
              <div className="mt-4">
                <ClaimedButton />
              </div>
            )
            : (
              <CompleteTheTaskButton onClick={() => handleQuestStart(selectedQuest as QuestT)} />
            )}
        </div>
      </Drawer>
      <Drawer
        side="bottom"
        open={openEnergyDrawer}
        onClose={() => setOpenEnergyDrawer(false)}
        closeStroke="gray-10"
        wrapperClassName="flex flex-col overflow-y-scroll h-80 shadow-xl rounded-t-[20px] bg-gradient-to-b from-[#00387A] to-[#002864] z-[99999] relative stroke-gray-10"
      >
        <EnergyDrawerContent handleEnergyFill={handleEnergyFill} />
      </Drawer>
      <div className="bg-black-modal-bg h-32" />
    </div>
  );
}

export default QuestPage;
